"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FreeCoffeeCardContent = exports.FreeCoffeeCardStatus = void 0;
var FreeCoffeeCardStatus;
(function (FreeCoffeeCardStatus) {
    FreeCoffeeCardStatus["PENDING"] = "pending";
    FreeCoffeeCardStatus["SEE_UPGRADE_CAN_GET_COFFEE"] = "see_upgrade_can_get_coffee";
    FreeCoffeeCardStatus["DOES_NOT_SEE_UPGRADE_CAN_GET_COFFEE"] = "does_not_see_upgrade_can_get_coffee";
    FreeCoffeeCardStatus["CREDIT_SCORE_ALOMST_THERE"] = "credit_score_almost_there";
    FreeCoffeeCardStatus["INELGIBILE"] = "ineligible";
    FreeCoffeeCardStatus["WAITLIST_DUE_TO_STATE"] = "waitlist_due_to_state";
    FreeCoffeeCardStatus["WAITLIST_CATCHALL"] = "waitlist_catchall";
})(FreeCoffeeCardStatus = exports.FreeCoffeeCardStatus || (exports.FreeCoffeeCardStatus = {}));
var FreeCoffeeCardContent;
(function (FreeCoffeeCardContent) {
    FreeCoffeeCardContent["DETERMINING_ELIGIBLITY"] = "determining_eligiblity";
    FreeCoffeeCardContent["CONNECT_PLAID"] = "connect_plaid";
    FreeCoffeeCardContent["GET_COFFEE"] = "get_coffee";
    FreeCoffeeCardContent["GET_COFFEE_AND_UPSELL"] = "get_coffee_and_upsell";
    FreeCoffeeCardContent["COUNTDOWN"] = "countdown";
    FreeCoffeeCardContent["ALMOST_THERE_INCREASE_YOUR_SCORE"] = "almost_there_increase_your_score";
    FreeCoffeeCardContent["INELIGIBLILTY_REASONS"] = "ineligiblilty_reasons";
    FreeCoffeeCardContent["JOIN_WAITLIST_DUE_TO_STATE"] = "join_waitlist_due_to_state";
    FreeCoffeeCardContent["JOIN_WAITLIST_CATCHALL"] = "join_waitlist_catchall";
    FreeCoffeeCardContent["ON_WAITLIST"] = "on_waitlist";
})(FreeCoffeeCardContent = exports.FreeCoffeeCardContent || (exports.FreeCoffeeCardContent = {}));
