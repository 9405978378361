"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LoanApplicationStatus = exports.LoanApplicationType = void 0;
/** @TranslateToPython @TranslateToEnum */
var LoanApplicationType;
(function (LoanApplicationType) {
    LoanApplicationType["AVEN_ORIGINATION"] = "AVEN_ORIGINATION";
    LoanApplicationType["AVEN_ORIGINATION_DRYRUN"] = "AVEN_ORIGINATION_DRYRUN";
    LoanApplicationType["AVEN_MLO_ORIGINATION"] = "AVEN_MLO_ORIGINATION";
    LoanApplicationType["AVEN_HOME_REATTACH"] = "AVEN_HOME_REATTACH";
    LoanApplicationType["AVEN_HOME_REUNDERWRITING_LINE_SIZE_INCREASE"] = "AVEN_HOME_REUNDERWRITING_LINE_SIZE_INCREASE";
    LoanApplicationType["AVEN_HOME_REUNDERWRITING_LINE_SIZE_INCREASE_DRYRUN"] = "AVEN_HOME_REUNDERWRITING_LINE_SIZE_INCREASE_DRYRUN";
    LoanApplicationType["AVEN_HOME_REUNDERWRITING_APR_REDUCTION"] = "AVEN_HOME_REUNDERWRITING_APR_REDUCTION";
    LoanApplicationType["AVEN_HOME_CLI_AND_APR_REDUCTION_AND_BALANCE_SWEEP"] = "AVEN_HOME_CLI_AND_APR_REDUCTION_AND_BALANCE_SWEEP";
    LoanApplicationType["AVEN_HOME_CLI_AND_APR_REDUCTION_AND_BALANCE_SWEEP_DRYRUN"] = "AVEN_HOME_CLI_AND_APR_REDUCTION_AND_BALANCE_SWEEP_DRYRUN";
    LoanApplicationType["_AVEN_WAITLIST"] = "_AVEN_WAITLIST";
    LoanApplicationType["_AVEN_HOME_REUNDERWRITING_ANNUAL_RENEWAL"] = "AVEN_HOME_REUNDERWRITING_ANNUAL_RENEWAL";
    LoanApplicationType["MORTGAGE_BROKERING"] = "MORTGAGE_BROKERING";
    LoanApplicationType["AVEN_HOME_REUNDERWRITING_POLICY_CHANGE"] = "AVEN_HOME_REUNDERWRITING_POLICY_CHANGE";
    LoanApplicationType["AVEN_UCC_ORIGINATION"] = "AVEN_UCC_ORIGINATION";
    LoanApplicationType["AVEN_UCC_ORIGINATION_DRYRUN"] = "AVEN_UCC_ORIGINATION_DRYRUN";
    LoanApplicationType["AVEN_MORTGAGE_ORIGINATION"] = "AVEN_MORTGAGE_ORIGINATION";
})(LoanApplicationType = exports.LoanApplicationType || (exports.LoanApplicationType = {}));
// Todo This is **NOT** the enum we use on the backend, but we should consolidate! Currently
//  this particular enum is only used on the frontend.
// Keep in sync with LoanApplicationStatus in backend
var LoanApplicationStatus;
(function (LoanApplicationStatus) {
    LoanApplicationStatus["pending"] = "pending";
    // The suspense status is used to salvage LAs which would otherwise have been denied/ineligible
    // applicants would be able to upload documents to dispute the reason for their denial, if applicable.
    // If they are successful, they will be run through UW again with an override for their denial.
    LoanApplicationStatus["decisionSuspended"] = "decisionSuspended";
    LoanApplicationStatus["lienInformationVerification"] = "lienInformationVerification";
    LoanApplicationStatus["lienInformationVerified"] = "lienInformationVerified";
    LoanApplicationStatus["nameMismatchVerification"] = "nameMismatchVerification";
    LoanApplicationStatus["nameMismatchVerified"] = "nameMismatchVerified";
    LoanApplicationStatus["preQualified"] = "preQualified";
    LoanApplicationStatus["preQualificationAccepted"] = "preQualificationAccepted";
    // For flows like ucc where we don't have a notary, but we still need to wait for the applicant to sign the documents
    LoanApplicationStatus["awaitingDocumentSignatures"] = "awaitingDocumentSignatures";
    // all applicants have signed the documents
    LoanApplicationStatus["documentSignaturesComplete"] = "documentSignaturesComplete";
    LoanApplicationStatus["incomeVerification"] = "incomeVerification";
    /**
     * @deprecated https://app.asana.com/0/1202417121136654/1205534272232339/f deprecate plaid verification state and queue
     */
    LoanApplicationStatus["_plaidVerification"] = "plaidVerification";
    LoanApplicationStatus["incomeVerified"] = "incomeVerified";
    LoanApplicationStatus["onsiteAVMVerification"] = "onsiteAVMVerification";
    LoanApplicationStatus["onsiteAVMVerified"] = "onsiteAVMVerified";
    LoanApplicationStatus["involuntaryLienVerification"] = "involuntaryLienVerification";
    LoanApplicationStatus["involuntaryLienVerified"] = "involuntaryLienVerified";
    LoanApplicationStatus["activeForeclosureVerification"] = "activeForeclosureVerification";
    LoanApplicationStatus["activeForeclosureVerified"] = "activeForeclosureVerified";
    LoanApplicationStatus["ofacVerification"] = "ofacVerification";
    LoanApplicationStatus["ofacVerified"] = "ofacVerified";
    LoanApplicationStatus["offered"] = "offered";
    LoanApplicationStatus["accepted"] = "accepted";
    LoanApplicationStatus["coOwnerDispute"] = "coOwnerDispute";
    LoanApplicationStatus["floodInsuranceVerification"] = "floodInsuranceVerification";
    // offer accepted AND coOwner / trust information has been gathered
    LoanApplicationStatus["awaitingSuccessfulNotarizations"] = "awaitingSuccessfulNotarizations";
    // all applicants have gone through all the notarizations successfully but the account has not been approved yet due to fraud flag
    LoanApplicationStatus["notarizationsComplete"] = "notarizationsComplete";
    // applicant contacted us to request withdrawal of application prior to approved
    LoanApplicationStatus["withdrawn"] = "withdrawn";
    // application was offered, but applicant refused to accept
    LoanApplicationStatus["declined"] = "declined";
    LoanApplicationStatus["approved"] = "approved";
    LoanApplicationStatus["denied"] = "denied";
    // Context of non-terminal status trialDenialDm,
    //   1) When one DM user is rejected by underwriting, we send the LA to trialDenialDM,
    //   2) Frontend shows the personal information page for user to provide/fix personal info (mainly SSN),
    //   3) Then another underwriting is triggered, if successful the non-terminal trialDenialDM status is override by other status;
    //      Otherwise, the loan application keeps the status trialDenialDM, and AAN will be sent out.
    // TODO(xuemeiliu): Deprecate trialDenialDm after migrating all underwriting logic to python.
    // Essentially, trialDenialDm = unsuccessful_loan_app_status && DM_prequal. However, such override
    // looses information and causes troubles in followup decision of one loan application. For example,
    // long-standing trialDenialDm with original status as humanInvestigate should still be directed for
    // human review, while those with origianl denied/ineligible status should have AAN delivered.
    //
    // After the migration, PythonDataReport will include both loan app status and isDmPrequal.
    LoanApplicationStatus["trialDenialDm"] = "trialDenialDm";
    LoanApplicationStatus["deniedIndividualCanAddCoApplicant"] = "deniedIndividualCanAddCoApplicant";
    LoanApplicationStatus["humanInvestigate"] = "humanInvestigate";
    LoanApplicationStatus["ineligible"] = "ineligible";
    LoanApplicationStatus["incomplete"] = "incomplete";
    // In Jan 2024, we split original 'incomplete' status into two groups:
    //   - 'incomplete' where we've never offered to this LA;
    //   - 'offeredNotApproved' where we've offered to this LA.
    // We need this breakdown for reporting purpose, i.e., 'offeredNotApproved' LAs should not be
    // reported as NOIed. See this doc for more details: https://docs.google.com/document/d/1tRA9jQv5AB13GxMtuIHpyUdh3dOndHOz6Mfx2e9Cars/edit#heading=h.rmtsqj2je9e8
    LoanApplicationStatus["offeredNotApproved"] = "offeredNotApproved";
    LoanApplicationStatus["duplicate"] = "duplicate";
    LoanApplicationStatus["blocked"] = "blocked";
    LoanApplicationStatus["mobileNotary"] = "mobileNotary";
    // Application never reached approved status, but account was closed
    LoanApplicationStatus["closedAccountNeverApproved"] = "closedAccountNeverApproved";
})(LoanApplicationStatus = exports.LoanApplicationStatus || (exports.LoanApplicationStatus = {}));
