import { useGlobalUiStore } from '@/store/globalUiStore'
import * as Sentry from '@sentry/browser'
import inspect from 'object-inspect'
import { useAccountClosureStore } from '@/store/accountClosureStore'
import { useCashOutAndBalanceTransferStore } from '@/store/cashOutAndBalanceTransferStore'
import { useClickTrackingStore } from '@/store/clickTrackingStore'
import { useCreditCardMarketDataStore } from '@/store/creditCardMarketDataStore'
import { useCustomerInfoStore } from '@/store/customerInfoStore'
import { useDocumentsStore } from '@/store/documentsStore'
import { useEstimatedSavingsStore } from '@/store/estimatedSavingsStore'
import { useExperimentStore } from '@/store/experimentStore'
import { useHomeGuaranteeStore } from '@/store/homeGuaranteeStore'
import { useOverviewStore } from '@/store/overviewStore'
import { usePaymentsStore } from '@/store/paymentsStore'
import { usePersonalFinancialLiabilityStore } from '@/store/personalFinancialLiabilityStore'
import { useRewardsStore } from '@/store/rewardsStore'
import { useSingleUseTokenStore } from '@/store/singleUseTokenStore'
import { useUpgradeOffersStore } from '@/store/upgradeOffersStore'
import { useEngagementSipOffersStore } from '@/store/engagementSipOffersStore'

/** When the API returns success = false */
export const API_ERROR = 'API_ERROR'
export const NETWORK_ERROR = 'NETWORK_ERROR'
export const SERVER_RESPONSE_ERROR = 'SERVER_RESPONSE_ERROR'

export const parseError = (error) => {
    const globalUiStore = useGlobalUiStore()
    if (error.response) {
        globalUiStore.error = SERVER_RESPONSE_ERROR
    } else if (error.request) {
        globalUiStore.error = NETWORK_ERROR
    } else {
        Sentry.captureException(inspect(error))
    }
}

// Todo Right now if a dev adds a new store, it's up to them to remember to
//  reset the store here. Obviously, people are going to forget, so we need
//  some compile time check or test.
export const resetAllProductSpecificStoresRequiringAuthentication = () => {
    // Does not clear customerInfoStore because that's customer level data, not product level.
    // Does not clear sessionStore, deviceInfoStore or globalUiStore because data in those
    // stores is not protected by cardholder authentication; it's either auth tokens themselves,
    // metadata about the physical devices the app is running on or UI related data not
    // specific to the cardholder.
    useAccountClosureStore().$reset()
    useCashOutAndBalanceTransferStore().$reset()
    useClickTrackingStore().$reset()
    useCreditCardMarketDataStore().$reset()
    useDocumentsStore().$reset()
    useEstimatedSavingsStore().$reset()
    useExperimentStore().$reset()
    useHomeGuaranteeStore().$reset()
    useOverviewStore().$reset()
    usePaymentsStore().$reset()
    usePersonalFinancialLiabilityStore().$reset()
    useRewardsStore().$reset()
    useSingleUseTokenStore().$reset()
    useUpgradeOffersStore().$reset()
    useEngagementSipOffersStore().$reset()
}

export const resetAllStoresRequiringAuthentication = () => {
    useCustomerInfoStore().$reset()
    resetAllProductSpecificStoresRequiringAuthentication()
}
