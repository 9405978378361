"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HumanReadableLocationForPromotion = exports.PromotionStatus = exports.PromotionName = void 0;
/* @TranslateToPython @TranslateToEnum */
var PromotionName;
(function (PromotionName) {
    PromotionName["freeAmazonPrime"] = "freeAmazonPrime";
    PromotionName["amazonTenDollarCredit"] = "amazonTenDollarCredit";
    PromotionName["amazonTwentyFiveDollarCredit"] = "amazonTwentyFiveDollarCredit";
    PromotionName["taxPaymentCredit"] = "taxPaymentCredit";
    PromotionName["costcoFreeMembership"] = "costcoFreeMembership";
    PromotionName["restaurants7Percent"] = "restaurants7Percent";
    PromotionName["amazonPrime"] = "amazonPrime";
    PromotionName["gasAndGroceries7Percent"] = "gasAndGroceries7Percent";
})(PromotionName = exports.PromotionName || (exports.PromotionName = {}));
var PromotionStatus;
(function (PromotionStatus) {
    PromotionStatus["notEnrolled"] = "notEnrolled";
    PromotionStatus["isEnrolled"] = "isEnrolled";
    PromotionStatus["conditionsFulfilled"] = "conditionsFulfilled";
    PromotionStatus["activatedByCustomer"] = "activatedByCustomer";
    PromotionStatus["promotionPaid"] = "promotionPaid";
})(PromotionStatus = exports.PromotionStatus || (exports.PromotionStatus = {}));
var HumanReadableLocationForPromotion;
(function (HumanReadableLocationForPromotion) {
    HumanReadableLocationForPromotion["orangeCounty"] = "Orange County";
    HumanReadableLocationForPromotion["arizona"] = "Arizona";
    HumanReadableLocationForPromotion["phoenix"] = "Phoenix";
    HumanReadableLocationForPromotion["ohio"] = "Ohio";
    HumanReadableLocationForPromotion["cleveland"] = "Cleveland";
    HumanReadableLocationForPromotion["denver"] = "Denver";
})(HumanReadableLocationForPromotion = exports.HumanReadableLocationForPromotion || (exports.HumanReadableLocationForPromotion = {}));
