<script setup lang="ts">
    import ListRow from '@/components/ListRow.vue'
    import { RouteNames, RoutePaths } from '@/routes/router.types'
    import BalanceDetailsCreditCard from '@/components/BalanceDetailsCreditCard.vue'
    import { computed, onMounted, ref } from 'vue'
    import { useUpgradeOffersStore } from '@/store/upgradeOffersStore'
    import { inspect, logger } from '@/utils/logger'
    import { i18n } from '@/utils/i18n'
    import { toFormattedUSDNoCents } from '@/mixins/format'
    import { logEvent } from '@/services/http-client'
    import { useRouter } from 'vue-router/composables'
    import ModalDialog from 'aven_shared/src/components/ModalDialog.vue'
    import { AvenHomeCardUpgradeVariant } from '@/views/avenHomeCardUpgrade.types'

    interface Props {
        currentBalance: number
        cardSubtitle: string
        hasFullLineSizeContingencies: boolean
        allowedToRequestFullLineSize: boolean
        requestFullCreditLimitTitle: string
    }
    const props = defineProps<Props>()

    const displayDisclosures = ref(false)

    const router = useRouter()
    const upgradeOffersStore = useUpgradeOffersStore()

    const upgradeDisclosures = computed(() => {
        return upgradeOffersStore.getUpgradeDisclosures(AvenHomeCardUpgradeVariant.lineSize)
    })

    const onUpgradeBeardClick = async () => {
        logEvent('click_aven_my_ucc_cardholder_to_home_upgrade_beard', {
            offer: upgradeOffersStore.upgradeOfferToDisplay,
        })
        return await router.push({
            name: RouteNames.AVEN_HOME_CARD_UPGRADE,
            params: {
                variant: AvenHomeCardUpgradeVariant.lineSize,
                originatingSourceName: 'balanceDetailsBeard',
            },
        })
    }

    const onToggleDisclosures = () => {
        displayDisclosures.value = !displayDisclosures.value
        logEvent('click_aven_my_ucc_cardholder_to_home_upgrade_beard_toggle_disclosures', {
            offer: upgradeOffersStore.upgradeOfferToDisplay,
        })
    }

    onMounted(async () => {
        await useUpgradeOffersStore().tryRunAndGetUccCardholderHomeUpgradeOffer()

        if (upgradeOffersStore.upgradeOfferToDisplay) {
            logEvent('view_aven_my_ucc_cardholder_to_home_upgrade_beard', {
                offer: upgradeOffersStore.upgradeOfferToDisplay,
            })
            logger.info(`UCC cardholder upgrade offer to display is ${inspect(upgradeOffersStore.upgradeOfferToDisplay)}`)
        } else {
            logger.info(`No UCC cardholder upgrade offer to display`)
        }
    })
</script>

<template>
    <div
        class="card card-shadow rounded-3"
        :class="{ 'tw-bg-dark-green': !!upgradeOffersStore.upgradeOfferToDisplay }"
    >
        <balance-details-credit-card
            data-testid="activity-balance"
            :current-balance="currentBalance"
            :sub-title="cardSubtitle"
        />
        <transition name="fade">
            <div
                v-if="props.hasFullLineSizeContingencies && props.allowedToRequestFullLineSize"
                class="d-flex justify-content-center align-items-center my-1 card-shadow"
            >
                <b>
                    <list-row
                        :show-arrow="true"
                        :title="props.requestFullCreditLimitTitle"
                        :href-link="RoutePaths.REQUEST_FULL_CREDIT_LIMIT"
                    />
                </b>
            </div>
            <div
                v-else-if="upgradeOffersStore.upgradeOfferToDisplay"
                class="tw-text-white m-2 card-shadow"
                @click="onUpgradeBeardClick"
                @keydown.enter="onUpgradeBeardClick"
            >
                <!--
                Using .stop here means the parent's listener won't respond when the disclosure listener is triggered.
                This is helpful because it makes the entire beard clickable, rather than just small pieces of text.
                -->
                <b><span
                    @click.stop="onToggleDisclosures"
                    @keydown.enter.stop="onToggleDisclosures"
                >
                    {{ toFormattedUSDNoCents(upgradeOffersStore.upgradeOfferToDisplay.lineSize) }}<sup>1</sup>
                </span>
                </b>
                <span
                    class="tw-me-1"
                    v-html="i18n.t('components.balanceDetailsCard.ucc.upgradeBeard.text')"
                />
                <img
                    src="@/assets/navigation-right-arrow.svg"
                    alt="continue arrow"
                >
            </div>
        </transition>
        <modal-dialog
            :show="displayDisclosures"
            @close="onToggleDisclosures"
        >
            <div class="tw-px-4">
                <ol class="tw-text-gray-400 tw-p-0 tw-text-[12px]">
                    <li
                        value="1"
                        v-html="upgradeDisclosures"
                    />
                </ol>
            </div>
        </modal-dialog>
    </div>
</template>

<style lang="scss" scoped>
    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.5s ease;
    }

    .fade-enter-from,
    .fade-leave-to {
        opacity: 0;
    }
</style>
