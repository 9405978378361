"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClaimCardholderToCardholderReturnCodes = exports.PifRewardType = void 0;
// reward type is meant to support different variations of the Pay It Forward concept
// a `null` in  this column means that the PIF is a classicPif implementation
/** @TranslateToEnum */
var PifRewardType;
(function (PifRewardType) {
    PifRewardType["classicPif"] = "classicPif";
    PifRewardType["travelRewardsV0"] = "travelRewardsV0";
    PifRewardType["travelTicketsBahamas"] = "travelTicketsBahamas";
    /** groupCardholderRewards500USDToPrime gives both the source and the target a $500 statement credit (for suggested spend at Amazon Prime) */
    PifRewardType["groupCardholderRewards500USDToPrime"] = "groupCardholderRewards500USDToPrime";
})(PifRewardType = exports.PifRewardType || (exports.PifRewardType = {}));
/** @TranslateToEnum */
var ClaimCardholderToCardholderReturnCodes;
(function (ClaimCardholderToCardholderReturnCodes) {
    /** Non Aven credit cards cannot claim pif invites (ie Auto Products) */
    ClaimCardholderToCardholderReturnCodes["unsupportedProduct"] = "unsupportedProduct";
    /** Invite code was not found in the Mail table or Mail record was not valid */
    ClaimCardholderToCardholderReturnCodes["invalidInvite"] = "invalidInvite";
    /** A LA Id cannot claim their own invite */
    ClaimCardholderToCardholderReturnCodes["selfInvitationError"] = "selfInvitationError";
    /** LA Id has already claimed this PIF invite code OR has claimed another invite code by the same rewardType */
    ClaimCardholderToCardholderReturnCodes["alreadyClaimedByThisCardholder"] = "alreadyClaimedByThisCardholder";
    /** Another LA Id has already claimed this PIF */
    ClaimCardholderToCardholderReturnCodes["alreadyClaimedByAnotherCardholder"] = "alreadyClaimedByAnotherCardholder";
    /** Successful PIF claim */
    ClaimCardholderToCardholderReturnCodes["success"] = "success";
})(ClaimCardholderToCardholderReturnCodes = exports.ClaimCardholderToCardholderReturnCodes || (exports.ClaimCardholderToCardholderReturnCodes = {}));
