"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UpgradeCardType = void 0;
var UpgradeCardType;
(function (UpgradeCardType) {
    UpgradeCardType["creditCardBalanceTransfer"] = "creditCardBalanceTransfer";
    UpgradeCardType["personalLoanRefi"] = "personalLoanRefi";
    UpgradeCardType["genericAvenCash"] = "genericAvenCash";
    UpgradeCardType["genericMoap"] = "genericMoap";
    UpgradeCardType["dryrunMoap"] = "dryrunMoap";
    UpgradeCardType["none"] = "none";
})(UpgradeCardType = exports.UpgradeCardType || (exports.UpgradeCardType = {}));
