<template>
    <div class="tab-activity">
        <app-header
            :title="title"
            :on-title-back-click="hasMultipleProducts ? onTitleBackClick : undefined"
            class="mobile-header"
        />
        <div class="container position-relative px-0 px-md-3">
            <div
                class="account-overview mb-2"
                v-show="!accountIsClosed"
            >
                <balance-details-full-draw
                    v-if="isFullLineMinDrawProduct"
                    :current-balance="currentBalance"
                    :card-subtitle="cardSubtitle"
                />
                <balance-details-card
                    v-else
                    class="mb-2 mb-md-3"
                    :request-full-credit-limit-title="requestFullCreditLimitTitle"
                    :current-balance="currentBalance"
                    :allowed-to-request-full-line-size="allowedToRequestFullLineSize"
                    :card-subtitle="cardSubtitle"
                    :has-full-line-size-contingencies="hasFullLineSizeContingencies"
                />
                <common-actions />

                <button
                    class="tw-mt-6 tw-border-0 tw-p-4 tw-rounded-xl tw-text-left"
                    @click="$router.push(RoutePaths.ENGAGEMENT_LINK_BANK_ACCOUNT)"
                    v-if="showEngagementLinkBankAccountCard"
                >
                    <div class="tw-flex tw-items-center tw-gap-3">
                        <div>
                            <div class="tw-mb-1 tw-uppercase tw-font-bold tw-text-xs tw-tracking-[2px]">
                                {{ $t('pages.activity.requiredLinkBankAccount') }}
                            </div>
                            <p class="tw-mb-0">
                                {{ hasCreditLineIncrease ? $t('pages.activity.cliRequiredLinkBankAccountDescription') : $t('pages.activity.requiredLinkBankAccountDescription') }}
                            </p>
                        </div>
                        <img
                            alt="Arrow"
                            src="@/assets/icon-right-arrow.svg"
                        >
                    </div>
                </button>
                <button
                    class="mt-3 mx-2 border-0 p-2 rounded-3 text-start engagement-offer"
                    @click="clickEngagementFullDrawOfferButton"
                    v-if="hasEngagementFullDrawOffer"
                >
                    <div class="d-flex">
                        <div class="d-flex align-items-center pe-2">
                            <div class="engagement-offer-img p-1 rounded">
                                <img
                                    src="@/assets/images/pages/card/FullDrawCashOut-2.svg"
                                    width="32"
                                    height="32"
                                    alt="full-draw"
                                >
                            </div>
                        </div>
                        <div class="d-flex flex-column">
                            <div class="d-flex justify-content-between mb-1">
                                <div class="fw-bold">
                                    {{ engagementFullDrawOfferTitle }}
                                </div>
                            </div>
                            <div class="text-start small">
                                {{ engagementFullDrawOfferSubtitle }}
                            </div>
                            <div class="text-start small fw-bold">
                                {{ engagementFullDrawOfferExpirationStr }}
                            </div>
                        </div>
                        <div class="d-flex align-items-center ps-2">
                            <img
                                src="@/assets/icon-right-arrow.svg"
                                alt="Arrow"
                            >
                        </div>
                    </div>
                </button>
            </div>
            <card-block
                v-if="canShowCreditLimitIncreaseCard"
                :title="$t('pages.activity.requestHigherCreditLimitTitle')"
                class="mb-2"
            >
                <p>{{ $t('pages.activity.requestHigherCreditLimitDescription') }}</p>
                <base-button @click="clickCreditLimitIncreaseButton">
                    {{ $t('pages.activity.requestHigherCreditLimitButton') }}
                </base-button>
            </card-block>

            <!-- TODO: if someone were to have multiple feed units showing at once, it'll display "YOUR OFFERS" then feed 1 then "YOUR OFFERS" again then feed 2. -->
            <!-- P2 for now as the only people in more than one promo are employees -->
            <costco-promotion-feed-unit />
            <gas-and-groceries-7-feed-unit />
            <restaurants-7-feed-unit />

            <div
                v-for="upgradeCardData in avenUpgradeCardData"
                :key="upgradeCardData.upgradeCardType"
            >
                <ucc-to-home-upgrade-card
                    class="mb-2"
                    :upgrade-card-data="upgradeCardData"
                />
            </div>

            <pay-it-forward-card
                @open-pif-info-dialog="openPifInfoModal"
                v-if="showPifInfoCard"
                class="mb-2"
                :screen-name="PifScreen.ACTIVITY"
            />

            <card-block
                :title="minimumDue > 0 ? $t('pages.WhatIsDue.whatIsDue') : $t('pages.WhatIsDue.nothingDue')"
                class="mb-2"
            >
                <what-is-due />
            </card-block>

            <ucc-to-home-dryrun-upgrade-card />

            <creator-challenge-card
                v-if="shouldShowCreatorChallengeCard"
                class="mb-2"
            />

            <!--
            I check showBalanceTransferFeature both here and within the component.
            This is intentionally over-prescriptive to be safe against future changes, and so future engineers can clearly see the pattern.
            If you want to remove one of the checks, remove this superfluous one and keep the check within the component.
            -->
            <balance-transfer-card v-if="shouldShowBalanceTransferCard" />

            <!-- Upcoming AutoPay date and amount -->
            <card-block
                :title="$t('pages.activity.Upcoming')"
                v-if="(autoPaySetting.isEnabled && nextAutoPayAmount > 0) || scheduledPayments.length > 0"
                class="mb-2"
            >
                <transaction-row
                    v-if="autoPaySetting.isEnabled && nextAutoPayAmount > 0"
                    :title="$t('pages.activity.autoPay')"
                    :date="formatDateUtc(autopayNextPaymentDisplayDate)"
                    highlight-color="text-success"
                    :value="autoPayAmount"
                    :show-arrow="true"
                    @onClick="$router.push(RoutePaths.AUTO_PAY)"
                />
                <transaction-row
                    v-for="[idx, value] of scheduledPayments.entries()"
                    :key="idx"
                    :title="$t('pages.activity.scheduledPayment')"
                    :date="dayjs(value.scheduledPaymentDate).format(DISPLAY_DATE_FORMAT)"
                    :data-testid="`scheduled-payment-option-${idx + 1}`"
                    highlight-color="text-success"
                    :value="prettyPrintDollarAmount(value.paymentAmount)"
                    :show-arrow="true"
                    @onClick="
                        $router.push({
                            name: RouteNames.SINGLE_SCHEDULED_PAYMENT,
                            params: {
                                scheduledOneTimePaymentId: scheduledPayments[idx].id,
                                scheduledPaymentAmount: scheduledPayments[idx].paymentAmount,
                                scheduledPaymentDate: scheduledPayments[idx].scheduledPaymentDate,
                                last4AccountNumber: scheduledPayments[idx].last4AccountNumber,
                            },
                        })
                    "
                />
            </card-block>

            <mortgage-cashback-v2-card v-if="showMortgageCashbackV2Card" />

            <mortgage-payment-rewards-card v-if="showMortgagePaymentRewards" />

            <refi-survey-card v-if="isInternalEmployee" />

            <transaction-list />
        </div>
        <modal
            :show="showPifInfoModal"
            :title="$t('pages.payItForwardContactList.infoModalTitle')"
            @close="closePifInfoModal"
        >
            <p
                class="text-center mb-3"
                v-html="$t(`components.pifFooter.${pifRewardType}.content`)"
            />
            <base-button @click="closePifInfoModal">
                {{ $t('pages.payItForwardContactList.infoModalClose') }}
            </base-button>
        </modal>
        <!-- <app-store-review-modal-->
        <!--     :visibility="showAppReviewModal"-->
        <!--     @close="showAppReviewModal = false"-->
        <!-- />-->
    </div>
</template>
<script>
    import Header from '../components/AppHeader'
    import generic from '@/utils/generic'
    import { logger } from '@/utils/logger'
    import { DISPLAY_DATE_FORMAT, NYC_TIMEZONE, TransactionType } from '@/data/constants'
    import dayjs from 'dayjs'
    import PayItForwardCard from '@/components/PayItForwardCard'
    import CommonActions from '@/components/CommonActions'
    import CardBlock from '@/components/CardBlock'
    import TransactionRow from '@/components/TransactionRow'
    import WhatIsDue from '@/components/WhatIsDue'
    import { i18n } from '@/utils/i18n'
    import { useOverviewStore } from '@/store/overviewStore'
    import { RouteNames, RoutePaths } from '@/routes/router.types'
    import { usePaymentsStore } from '@/store/paymentsStore'
    import { useCashOutAndBalanceTransferStore } from '@/store/cashOutAndBalanceTransferStore'
    import BaseButton from '@/components/base/BaseButton.vue'
    import Modal from '@/components/Modal.vue'
    import BalanceTransferCard from '@/components/BalanceTransferCard.vue'
    import format, { toFormattedAprStripTrailingZerosAfterDecimalPoint, toFormattedUSD, toFormattedUSDKs, toTitleCase } from '@/mixins/format'
    import { PifScreen } from '@/utils/pifManager'
    import RefiSurveyCard from '@/components/RefiSurveyCard.vue'
    import TransactionList from '@/components/TransactionList.vue'
    import { DebtProductAndMetaDataSet } from '@/services/avenAppApi'
    import CreatorChallengeCard from '@/components/CreatorChallengeCard.vue'
    import { CreatorChallengeExperimentBucket, useExperimentStore } from '@/store/experimentStore'
    import MortgagePaymentRewardsCard from '@/components/MortgagePaymentRewardsCard.vue'
    import CostcoPromotionFeedUnit from '@/components/CostcoPromotionFeedUnit.vue'
    import GasAndGroceries7FeedUnit from '@/components/GasAndGroceries7FeedUnit.vue'
    import Restaurants7FeedUnit from '@/components/Restaurants7FeedUnit.vue'
    import MortgageCashbackV2Card from '@/components/MortgageCashbackV2Card.vue'
    import { useCustomerInfoStore } from '@/store/customerInfoStore'
    import BalanceDetailsCard from '@/components/BalanceDetailsCard.vue'
    import BalanceDetailsFullDraw from '@/components/BalanceDetailsFullDraw.vue'
    import { shouldShowPayItForward } from '@/mixins/payItForwardMixin'
    import UccToHomeUpgradeCard from '@/components/UccToHomeUpgradeCard.vue'
    import UccToHomeDryrunUpgradeCard from '@/components/UccToHomeDryrunUpgradeCard.vue'
    import { useEngagementSipOffersStore } from '@/store/engagementSipOffersStore'
    import { appSessionStorage, localStorageKey } from '@/utils/storage'

    export default {
        name: 'Activity',
        components: {
            UccToHomeDryrunUpgradeCard,
            UccToHomeUpgradeCard,
            BalanceDetailsFullDraw,
            BalanceDetailsCard,
            MortgageCashbackV2Card,
            MortgagePaymentRewardsCard,
            CostcoPromotionFeedUnit,
            GasAndGroceries7FeedUnit,
            Restaurants7FeedUnit,
            CreatorChallengeCard,
            TransactionList,
            RefiSurveyCard,
            BalanceTransferCard,
            Modal,
            BaseButton,
            WhatIsDue,
            CardBlock,
            PayItForwardCard,
            'app-header': Header,
            CommonActions,
            TransactionRow,
        },
        mixins: [format],
        data() {
            return {
                showPifInfoModal: false,
                TransactionType,
                RoutePaths,
                DISPLAY_DATE_FORMAT,
                prettyPrintDollarAmount: generic.prettyPrintDollarAmount,
                showAppReviewModal: false,
            }
        },
        computed: {
            hasMultipleProducts() {
                return useCustomerInfoStore().hasMultipleProducts
            },
            title() {
                if (this.hasMultipleProducts) {
                    return toTitleCase(useCustomerInfoStore().currentlySelectedProductName)
                } else {
                    return this.$t('pages.activity.title')
                }
            },
            RouteNames() {
                return RouteNames
            },
            PifScreen() {
                return PifScreen
            },
            dayjs() {
                return dayjs
            },
            existingBalanceTransfers() {
                return useCashOutAndBalanceTransferStore().existingBalanceTransfers
            },
            existingCashOutTransfers() {
                return useCashOutAndBalanceTransferStore().existingCashOutTransfers
            },
            accountIsClosed() {
                return useOverviewStore().accountIsClosed
            },
            fastFundingLineSizeIfApproved() {
                return useOverviewStore().fastFunding.lineSizeIfApproved
            },
            paymentDueDate() {
                return useOverviewStore().paymentDueDate
            },
            autopayNextPaymentDisplayDate() {
                return useOverviewStore().autopayNextPaymentDisplayDate
            },
            minimumDue() {
                return useOverviewStore().minimumDue
            },
            nextStatementDate() {
                return useOverviewStore().nextStatementDate
            },
            currentBalance() {
                return useOverviewStore().currentBalance
            },
            autoPaySetting() {
                return useOverviewStore().autoPaySetting
            },
            scheduledPayments() {
                return useOverviewStore().scheduledPayments
            },
            rescissionInfo() {
                return useOverviewStore().rescissionInfo
            },
            statementRemainingBalance() {
                return useOverviewStore().statementRemainingBalance
            },
            availableCredit() {
                return useOverviewStore().availableCredit
            },
            apr() {
                return useOverviewStore().apr
            },
            nextAutoPayAmount() {
                return useOverviewStore().nextAutoPayAmount
            },
            cardRequiresActivation() {
                return useOverviewStore().cardRequiresActivation
            },
            isHomeProduct() {
                return useOverviewStore().isHomeProduct
            },
            didAcceptMethodFiTos() {
                return useOverviewStore().didAcceptMethodFiTos
            },
            balanceTransferBlockReason() {
                return useOverviewStore().balanceTransferBlockReason
            },
            isAccountBTCOEnabled() {
                return useOverviewStore().isAccountBTCOEnabled
            },
            isAccountCOEnabled() {
                return useOverviewStore().isAccountCOEnabled
            },
            isAccountBTEnabled() {
                return useOverviewStore().isAccountBTEnabled
            },
            isAccountInstantBTEnabled() {
                return useOverviewStore().isAccountInstantBTEnabled
            },
            showBalanceTransferFeature() {
                return useOverviewStore().showBalanceTransferFeature
            },
            canShowInstantBalanceTransferOptionsCard() {
                return useOverviewStore().canShowInstantBalanceTransferOptionsCard
            },
            shouldShowBalanceTransferCard() {
                const canShowInstantBtOptionsCard = this.canShowInstantBalanceTransferOptionsCard
                if (!canShowInstantBtOptionsCard.canShow) {
                    logger.info(`Cardholder shouldn't see instant balance transfer options card: ${JSON.stringify(canShowInstantBtOptionsCard)}. Hiding BT card in Activity`)
                    return false
                }
                logger.info(`Cardholder should see instant balance transfer options card in Activity: ${JSON.stringify(canShowInstantBtOptionsCard)}`)
                return true
            },
            shouldShowCreatorChallengeCard() {
                return useExperimentStore().getCreatorChallengeExperimentBucket === CreatorChallengeExperimentBucket.variant
            },
            autoPayAmount: function () {
                return generic.prettyPrintDollarAmount(Math.abs(this.nextAutoPayAmount).toString())
            },
            showPifInfoCard: function () {
                return !this.accountIsClosed && this.isHomeProduct && shouldShowPayItForward()
            },
            cardSubtitle: function () {
                return i18n
                    .t('pages.activity.Available', {
                        openToBuyAmount: this.formatAmount(this.availableCredit),
                    })
                    .toUpperCase()
            },
            hasFullLineSizeContingencies() {
                return useOverviewStore().fullLineSizeContingencies.length > 0
            },
            allowedToRequestFullLineSize() {
                return useOverviewStore().allowedToRequestFullLineSize
            },
            fullLineSize() {
                return useOverviewStore().fullLineSize
            },
            requestFullCreditLimitTitle() {
                return `Unlock ${toFormattedUSDKs(this.fullLineSize)} Limit`
            },
            isInternalEmployee() {
                return useOverviewStore().isInternalEmployee
            },
            showMortgagePaymentRewards() {
                return useOverviewStore().mortgagePaymentRewardsEnabled && !this.showMortgageCashbackV2Card
            },
            showMortgageCashbackV2Card() {
                return useOverviewStore().mortgagePaymentRewardsV2Enabled
            },
            creditLimitIncreaseApplicationUrl() {
                return useOverviewStore().creditLimitIncreaseApplicationUrl
            },
            canShowCreditLimitIncreaseCard() {
                return !!this.creditLimitIncreaseApplicationUrl
            },
            showEngagementLinkBankAccountCard() {
                return useOverviewStore().showEngagementLinkBankAccountCard
            },
            hasCreditLineIncrease() {
                return useOverviewStore().hasCreditLineIncrease
            },
            pifRewardType: function () {
                return useOverviewStore().pifRewardType
            },
            isFullLineMinDrawProduct() {
                return useOverviewStore().isFullLineMinDrawProduct
            },
            avenUpgradeCardData() {
                return useOverviewStore().avenUpgradeData
            },
            hasEngagementFullDrawOffer() {
                return useEngagementSipOffersStore().hasFullDrawOffer
            },
            engagementFullDrawAmountWithoutFee() {
                return useEngagementSipOffersStore().fullDrawAmountWithoutFee
            },
            engagementFullDrawFeeRatio() {
                return useEngagementSipOffersStore().fullDrawFeeRatio
            },
            engagementFullDrawExpirationDate() {
                return useEngagementSipOffersStore().fullDrawExpirationDate
            },
            engagementFullDrawInstallmentLoanOption() {
                return useEngagementSipOffersStore().fullDrawInstallmentLoanOption
            },
            engagementFullDrawOfferTitle() {
                if (this.hasEngagementFullDrawOffer) {
                    return i18n.t('pages.activity.engagementFullDrawOfferTitle', {
                        amount: toFormattedUSD(this.engagementFullDrawInstallmentLoanOption.loanAmount),
                    })
                }
                return ''
            },
            engagementFullDrawOfferSubtitle() {
                if (this.hasEngagementFullDrawOffer) {
                    return i18n.t('pages.activity.engagementFullDrawOfferSubtitle', {
                        amount: toFormattedUSD(this.engagementFullDrawInstallmentLoanOption.loanAmount),
                        monthlyPayment: toFormattedUSD(this.engagementFullDrawInstallmentLoanOption.monthlyInstallmentInDollars),
                        termLength: `${Math.floor(this.engagementFullDrawInstallmentLoanOption.loanTermInMonths / 12)}-Year ${
                            this.engagementFullDrawInstallmentLoanOption.loanTermInMonths % 12
                        }-Month`,
                        installmentApr: toFormattedAprStripTrailingZerosAfterDecimalPoint(this.round(this.engagementFullDrawInstallmentLoanOption.apr / 100, 4)),
                    })
                }
                return ''
            },
            engagementFullDrawOfferExpirationStr() {
                if (this.hasEngagementFullDrawOffer) {
                    return i18n.t('pages.activity.engagementFullDrawOfferExpiration', {
                        expirationDateStr: generic.utcToTimeZone(this.engagementFullDrawExpirationDate),
                    })
                }
                return ''
            },
        },
        mounted: function () {
            logger.info(`------ Activity Mounted ------`)
            this.$logEvent('view_activity', {
                balanceTransferBlockReason: this.balanceTransferBlockReason,
                didAcceptMethodFiTos: this.didAcceptMethodFiTos,
                isAccountBTCOEnabled: this.isAccountBTCOEnabled,
                isAccountCOEnabled: this.isAccountCOEnabled,
                isAccountBTEnabled: this.isAccountBTEnabled,
                isAccountInstantBTEnabled: this.isAccountInstantBTEnabled,
                showBalanceTransferFeature: this.showBalanceTransferFeature,
                hasFullLineSizeContingencies: this.hasFullLineSizeContingencies,
                allowedToRequestFullLineSize: this.allowedToRequestFullLineSize,
                canShowInstantBalanceTransferOptionsCard: this.canShowInstantBalanceTransferOptionsCard.canShow,
            })
            window.refreshData = this.refreshData

            // Showing the app review modal for a user who 1. has the recent native version 2. has not seen the modal before (handled in native) 3. has activated their card
            // Killing this atm since it was adding lots of friction
            // this.showAppReviewModal = useDeviceInfoStore().canAskForAppReview && !this.cardRequiresActivation
        },
        methods: {
            onTitleBackClick: async function () {
                if (this.hasMultipleProducts) {
                    this.$logEvent('click_button_show_change_products_view', {
                        ...useCustomerInfoStore().productInfoDetails,
                    })
                    await this.$router.push({ name: RouteNames.CHANGE_PRODUCT })
                } else {
                    logger.info(`Activity page title clicked, but cardholder only has 1 product, so no-op`)
                }
            },
            formatAmount(amount) {
                return generic.prettyPrintDollarAmount(amount)
            },
            formatDateUtc(date) {
                return generic.utcToTimeZone(date, NYC_TIMEZONE, DISPLAY_DATE_FORMAT)
            },
            refreshData: async function () {
                logger.info('Refresh transaction list')
                await usePaymentsStore().refreshAutoPayDetails()
                await useOverviewStore().updateAccountOverview({
                    debtProductAndMetaDataSet: DebtProductAndMetaDataSet.all,
                })
            },
            openPifInfoModal: function () {
                this.showPifInfoModal = true
            },
            closePifInfoModal: function () {
                this.showPifInfoModal = false
            },
            clickCreditLimitIncreaseButton: async function () {
                this.$logEvent('click_credit_limit_increase_card')
                if (this.creditLimitIncreaseApplicationUrl) {
                    window.open(this.creditLimitIncreaseApplicationUrl, '_blank')
                } else {
                    logger.error('Credit limit increase application url is not available')
                }
            },
            clickEngagementFullDrawOfferButton: async function () {
                this.$logEvent('click_engagement_full_draw_offer_card')
                appSessionStorage.setItem(localStorageKey.cashOutAmount, String(this.engagementFullDrawAmountWithoutFee))
                appSessionStorage.setItem(localStorageKey.amortizationAprPercent, this.engagementFullDrawInstallmentLoanOption.apr)
                appSessionStorage.setItem(localStorageKey.amortizationTerm, this.engagementFullDrawInstallmentLoanOption.loanTermInMonths)
                appSessionStorage.setItem(localStorageKey.amortizationTotalMonthlyPayment, this.engagementFullDrawInstallmentLoanOption.monthlyInstallmentInDollars)
                appSessionStorage.setItem(localStorageKey.simpleInterestLoanId, this.engagementFullDrawInstallmentLoanOption.simpleInterestLoanId)
                appSessionStorage.setItem(localStorageKey.loanType, this.engagementFullDrawInstallmentLoanOption.loanType)
                appSessionStorage.setItem(localStorageKey.cashOutFeeRatio, String(this.engagementFullDrawFeeRatio))
                return await this.$router.push({ name: RouteNames.CASH_OUT_METHOD, params: { useFullDraw: true } })
            },
        },
    }
</script>

<style lang="scss" scoped>
    .tab-activity {
        background-color: $gray-100;
    }

    .account-overview {
        background-color: $white;
        border-radius: 0;
        border: none;
        padding: 16px;

        @include media-breakpoint-up(md) {
            border-radius: $border-radius-lg;
            padding-top: 0;
            padding-left: 0;
            padding-right: 0;
        }
    }

    .engagement-offer {
        background-color: #d9eedc;
    }

    .engagement-offer-img {
        background-color: #336b40;
    }
</style>
