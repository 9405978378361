"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AmortizationSource = void 0;
/**  @TranslateToPython  */
var AmortizationSource;
(function (AmortizationSource) {
    AmortizationSource["CASH_OUT"] = "CASH_OUT";
    AmortizationSource["CREDIT_CARD_BALANCE_TRANSFER"] = "CREDIT_CARD_BALANCE_TRANSFER";
    AmortizationSource["INTERNAL_PLAN_TRANSFER"] = "INTERNAL_PLAN_TRANSFER";
    AmortizationSource["AUTO_LOAN_REFINANCE"] = "AUTO_LOAN_REFINANCE";
    AmortizationSource["STATE_RECORDING_CHARGE"] = "STATE_RECORDING_CHARGE";
    AmortizationSource["LOAN_CONVERSION"] = "LOAN_CONVERSION";
    AmortizationSource["HELOC_REFI_TRANSFER"] = "HELOC_REFI_TRANSFER";
    AmortizationSource["SIMPLE_INTEREST_LOAN_REMEDIATION"] = "SIMPLE_INTEREST_LOAN_REMEDIATION";
    // Balance sweep in MOFO, SuperCLI, or Defensive FMP.
    AmortizationSource["BALANCE_SWEEP"] = "BALANCE_SWEEP";
    AmortizationSource["CASH_OUT_UPFRONT_FOR_ACCOUNT_ACTIVATION"] = "CASH_OUT_UPFRONT_FOR_ACCOUNT_ACTIVATION";
    // Monthly payment calculation of SIPs in aven_frontend/aven/src/components/onboarding/MonthlyPaymentTableListItem.vue
    AmortizationSource["OG_MONTHLY_PAYMENT_CALCULATION_FOR_SIMPLE_INTEREST_LOAN"] = "OG_MONTHLY_PAYMENT_CALCULATION_FOR_SIMPLE_INTEREST_LOAN";
    /** Upgrade path to convert UCC cardholders to Home cardholders */
    AmortizationSource["UCC_TO_HOME_UPGRADE_DRYRUN"] = "UCC_TO_HOME_UPGRADE_DRYRUN";
    AmortizationSource["ENGAGEMENT_FULL_DRAW"] = "ENGAGEMENT_FULL_DRAW";
})(AmortizationSource = exports.AmortizationSource || (exports.AmortizationSource = {}));
