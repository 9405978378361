<script setup lang="ts">
    import { onMounted, ref } from 'vue'
    import { PromotionName } from 'aven_types'
    import { logEvent } from '@/services/http-client'
    import BaseButton from '@/components/base/BaseButton.vue'
    import CardBlock from '@/components/CardBlock.vue'
    import SectionHeader from '@/components/SectionHeader.vue'
    import { RoutePaths } from '@/routes/router.types'
    import { useQuickPromotionCommons, useQuickPromotionFeedUnitCommons } from '@/composables/useQuickPromotionCommons'
    import { toFormattedAprStripTrailingZerosAfterDecimalPoint } from '@/mixins/format'
    import { i18n } from '@/utils/i18n'

    const loading = ref(true)
    const promotionName = PromotionName.costcoFreeMembership
    const promotionPagePath = RoutePaths.COSTCO_REWARDS
    // 4% = 2% from Costco Executive Membership and 2% from Aven
    const COSTCO_CASHBACK_PERCENTAGE = 0.04

    const { shouldShowEnrolledFeedUnit, shouldShowClaimedFeedUnit, submitting, trySetShouldShowFeedUnitRefs, onClaimFeedUnit } = useQuickPromotionFeedUnitCommons(promotionName, promotionPagePath)
    const { copiedToClipboardRef, supportsNativeShare, humanReadableLocation, shareWithFriends, copyLink, trySetHumanReadableLocationForCustomer } = useQuickPromotionCommons(promotionName)

    // Set these up once we have location information
    const message = ref<string>('')
    const copyLinkWithMessageFn = ref<(() => Promise<void>) | null>(null)
    const shareWithFriendsWithMessageFn = ref<(() => Promise<void>) | null>(null)

    onMounted(async () => {
        loading.value = true
        await trySetShouldShowFeedUnitRefs()
        await trySetHumanReadableLocationForCustomer()
        message.value = i18n.t('pages.CostcoRewards.shareMessage', {
            cashBackPercentage: toFormattedAprStripTrailingZerosAfterDecimalPoint(COSTCO_CASHBACK_PERCENTAGE),
            inHumanReadableLocation: humanReadableLocation.value ? ` in ${humanReadableLocation.value}` : '',
        })
        copyLinkWithMessageFn.value = copyLink(message.value)
        shareWithFriendsWithMessageFn.value = shareWithFriends(message.value)
        loading.value = false
        if (shouldShowEnrolledFeedUnit.value === true) {
            logEvent('view_costco_rewards_feed_unit', { humanReadableLocation: humanReadableLocation.value })
        }
        if (shouldShowClaimedFeedUnit.value === true) {
            logEvent('view_costco_rewards_claimed_feed_unit', { humanReadableLocation: humanReadableLocation.value })
        }
    })
</script>

<template>
    <div>
        <div
            v-if="shouldShowEnrolledFeedUnit"
            class="mb-2"
        >
            <section-header override-class="container mt-3 mb-1 text-left">
                {{ $t('pages.CostcoRewards.feedUnit.yourOffers') }}
            </section-header>
            <card-block
                class="card-block"
                :no-horizontal-padding="true"
                :no-vertical-padding="true"
            >
                <div class="tw-p-6">
                    <div class="d-flex flex-row tw-mb-[18px] align-items-center">
                        <img
                            alt="Costco"
                            class="tw-size-[52px] tw-mr-[18px]"
                            src="@/assets/images/global/costco.webp"
                        >
                        <div class="flex-column">
                            <div
                                class="tw-font-bold tw-text-lg"
                                v-html="$t('pages.CostcoRewards.feedUnit.title')"
                            />
                            <div
                                class="tw-text-sm"
                                v-html="$t('pages.CostcoRewards.feedUnit.description')"
                            />
                        </div>
                    </div>
                    <base-button
                        @click="onClaimFeedUnit"
                        :submitting="submitting"
                        data-testid="costco-claim-button"
                    >
                        {{ $t('pages.CostcoRewards.feedUnit.claimButton') }}
                    </base-button>
                </div>
            </card-block>
        </div>
        <!-- I know it seems ugly to completely duplicate the template here but this template will likely change as designs change -->
        <div
            v-else-if="shouldShowClaimedFeedUnit"
            class="mb-2"
        >
            <card-block
                class="card-block"
                :no-horizontal-padding="true"
                :no-vertical-padding="true"
            >
                <div class="tw-p-6">
                    <div
                        class="tw-font-bold tw-mb-2"
                        v-html="$t('pages.CostcoRewards.feedUnit.claimedTitle')"
                    />
                    <div class="d-flex flex-row tw-mb-[18px] align-items-center">
                        <img
                            alt="Costco"
                            class="tw-size-12 tw-mr-4"
                            src="@/assets/images/global/costco.webp"
                        >
                        <div class="flex-column">
                            <div
                                class="tw-text-sm"
                                v-html="$t('pages.CostcoRewards.feedUnit.claimedDescription')"
                            />
                        </div>
                    </div>
                    <base-button
                        v-if="supportsNativeShare === true"
                        @click="shareWithFriendsWithMessageFn"
                        @keydown.enter="shareWithFriendsWithMessageFn"
                        button-classes="btn btn-secondary btn-block"
                        :submitting="submitting"
                        data-testid="costco-share-button"
                    >
                        {{ $t('pages.CostcoRewards.feedUnit.shareWithAFriend') }}
                    </base-button>
                    <base-button
                        v-else
                        class="tw-mb-4"
                        image-alt="Copy"
                        @click="copyLinkWithMessageFn"
                        image-classes="tw-inline-block tw-me-2"
                        button-classes="btn btn-secondary btn-block"
                        :image-src="copiedToClipboardRef ? '' : 'images/pages/card/Link.svg'"
                        data-testid="costco-share-message"
                    >
                        {{ copiedToClipboardRef ? $t('shared.copied') : $t('pages.CostcoRewards.feedUnit.shareWithAFriend') }}
                    </base-button>
                </div>
            </card-block>
        </div>
    </div>
</template>
