import { AvenBoostRewardsName, LoanAssetType, UnderwritingMetadata } from 'aven_types'
import { toFormattedPercentStripTrailingZerosAfterDecimalPoint } from '../utils/formatUtil'

// Todo this is a copy of aven_backend/src/manager/communication/messageTemplates/messageTemplates.ts getCashOutBalanceTransferFeeText(),
//  but usings UnderwritingMetadata instead of uw policy. We should consolidate those 2 methods.
export const cashOutBalanceTransferFeeText = (metadata: UnderwritingMetadata): string => {
    const isUcc = metadata.loanAssetType === LoanAssetType.personalPropertyAttestation
    // UCC only supports balance transfers
    // Todo Is is possible to determine whether cash outs are disabled via policy
    //  rather than whether the application is UCC or not?
    if (isUcc) {
        let formattedBalanceTransferFee
        if (metadata.MinBTFeePercent === metadata.MaxBTFeePercent) {
            formattedBalanceTransferFee = `is ${toFormattedPercentStripTrailingZerosAfterDecimalPoint(metadata.MinBTFeePercent)}`
        } else {
            formattedBalanceTransferFee = `ranges from ${toFormattedPercentStripTrailingZerosAfterDecimalPoint(metadata.MinBTFeePercent)} - ${toFormattedPercentStripTrailingZerosAfterDecimalPoint(
                metadata.MaxBTFeePercent
            )}`
        }
        return `Balance Transfer fee ${formattedBalanceTransferFee} of amount transferred, this fee is subject to change.`
    }
    // All CO and BT fees are the same (no range at all)
    else if (metadata.MinBTFeePercent === metadata.MaxBTFeePercent && metadata.MinCOFeePercent === metadata.MaxCOFeePercent && metadata.MinBTFeePercent === metadata.MinCOFeePercent) {
        return `Cash Out (draw to bank account) fee and Balance Transfer fee is ${toFormattedPercentStripTrailingZerosAfterDecimalPoint(
            metadata.MinBTFeePercent
        )} of amount transferred, this fee is subject to change.`
    }
    // The range of fees is the same for CO and BT
    else if (metadata.MinBTFeePercent === metadata.MinCOFeePercent && metadata.MaxBTFeePercent === metadata.MaxCOFeePercent) {
        return `Cash Out (draw to bank account) fee and Balance Transfer fee ranges from ${toFormattedPercentStripTrailingZerosAfterDecimalPoint(
            metadata.MinBTFeePercent
        )} - ${toFormattedPercentStripTrailingZerosAfterDecimalPoint(metadata.MaxBTFeePercent)} of amount transferred, this fee is subject to change.`
    } else {
        let formattedBalanceTransferFee
        if (metadata.MinBTFeePercent === metadata.MaxBTFeePercent) {
            formattedBalanceTransferFee = `is ${toFormattedPercentStripTrailingZerosAfterDecimalPoint(metadata.MinBTFeePercent)}`
        } else {
            formattedBalanceTransferFee = `ranges from ${toFormattedPercentStripTrailingZerosAfterDecimalPoint(metadata.MinBTFeePercent)} - ${toFormattedPercentStripTrailingZerosAfterDecimalPoint(
                metadata.MaxBTFeePercent
            )}`
        }
        const balanceTransferPart = `Balance Transfer fee ${formattedBalanceTransferFee} of amount transferred, this fee is subject to change.`
        let formattedCashOutFee
        if (metadata.MinCOFeePercent === metadata.MaxCOFeePercent) {
            formattedCashOutFee = `is ${toFormattedPercentStripTrailingZerosAfterDecimalPoint(metadata.MinCOFeePercent)}`
        } else {
            formattedCashOutFee = `ranges from ${toFormattedPercentStripTrailingZerosAfterDecimalPoint(metadata.MinCOFeePercent)} - ${toFormattedPercentStripTrailingZerosAfterDecimalPoint(
                metadata.MaxCOFeePercent
            )}`
        }
        const cashOutPart = `Cash Out (draw to bank account) fee ${formattedCashOutFee} of amount transferred, this fee is subject to change.`
        return `${balanceTransferPart} ${cashOutPart}`
    }
}

export const rateIndexDisclosure = (metadata: UnderwritingMetadata) => {
    const { rateIndexFullName, rateIndexShortName, rateIndexEffectiveDate, rateValuePercentage } = metadata

    const isWSJPrimeRate = rateIndexShortName.includes('WSJ')

    if (isWSJPrimeRate) {
        return `The APR is a variable rate based on the ${rateIndexFullName} (“Index”) published by the Wall Street Journal in its Money Rates section. The ${rateIndexShortName} as of ${rateIndexEffectiveDate} is ${rateValuePercentage}.`
    }

    return `The APR is a variable rate based on the ${rateIndexFullName} (“Index”). The ${rateIndexShortName} is determined by the Federal Reserve’s Federal Open Market Committee and published periodically by the Wall Street Journal in its Money Rates section. The ${rateIndexShortName} as of ${rateIndexEffectiveDate} is ${rateValuePercentage}.`
}

export const aprIsFootnote = (metadata: UnderwritingMetadata, aprLongName: string = 'The annual percentage rate') => {
    return `${aprLongName} rate ("APR") is the cost of credit as a yearly rate and does not include costs other than interest. ${rateIndexDisclosure(
        metadata
    )} Your initial APR is based on a margin, determined by your creditworthiness when you open your account, plus the Index at the time of application. Your APR is subject to change as allowed by applicable law. Best rates available only to the highest-qualified borrowers. The maximum APR will not exceed ${toFormattedPercentStripTrailingZerosAfterDecimalPoint(
        metadata.GlobalMaxAPR
    )} during the life of your account. ${cashOutBalanceTransferFeeText(
        metadata
    )} Recording fees may apply and vary from each state and county and are assessed by the clerk of the county where your property is located. Recording fees will be charged only where permitted by applicable state law.`
}

const cashBackAppliesTo = (isUcc: boolean): string => {
    return `Cash back applies to valid purchases (not on refunds or returns) made using the Aven card, and does not apply to non-card transactions, including balance transfers, ${
        isUcc ? '' : 'cash-outs,'
    } reward redemptions and redemptions through the Aven Travel portal.`
}

export const cashBackFootnote = (uwMetadata: UnderwritingMetadata) => {
    const { loanAssetType, ExperimentName, shouldUpdateCashbackAfterDisableAutoPay } = uwMetadata

    const boostCashBackNames = uwMetadata.boostCashBackNames ?? []
    const isFlights5pctEnabled = boostCashBackNames.includes(AvenBoostRewardsName.ascendaFlightsCashBack5pct)
    const isHotels7pctEnabled = boostCashBackNames.includes(AvenBoostRewardsName.ascendaHotelsCashBack7pct)
    const isUcc = loanAssetType === LoanAssetType.personalPropertyAttestation
    const rewardsTermsDoc = `https://www.aven.com/public/docs/CashbackTerms/${ExperimentName}`

    return `
        ${
            shouldUpdateCashbackAfterDisableAutoPay
                ? 'Cashback available to those who enroll in AutoPay, refer to <a class="text-decoration-underline" href="https://www.aven.com/docs/AutoPayAgreement.pdf" target="_blank">AutoPay Terms & Conditions</a>.'
                : ''
        }
        ${isHotels7pctEnabled ? `Hotels must be booked in the Aven Travel Portal to receive ${isFlights5pctEnabled ? '7%' : '7X'} cash back.` : ''}
        ${isFlights5pctEnabled ? `Flights must be booked in the Aven Travel Portal to receive 5% cash back.` : ''}
        ${cashBackAppliesTo(isUcc)}
        Cashback is accumulated as points and redeemable as statement credit or in the travel portal.
        Refer to <a class="text-decoration-underline" href="${rewardsTermsDoc}" target="_blank">Rewards Terms & Conditions</a>.
    `
}

export const autoPayDiscountFootnote = (): string => {
    // Todo Does the 0.25 come from uw policy? Seems like it should, so we should grab from there.
    return `You are not required to sign up for AutoPay. To receive a 0.25 percentage point discount to your APR, you must enroll in AutoPay by the end of your first billing cycle and maintain AutoPay. This discount is available to new cardholders only. If you fail to enroll, or if you discontinue AutoPay, your APR will increase by 0.25 percentage points. We reserve the right to terminate or modify the AutoPay discount program at any time without notice. See <a href="https://www.aven.com/docs/AutoPayAgreement.pdf" target="_blank">AutoPay Terms & Conditions</a> for details.`
}

export const fixedPlanFootnote = (): string => {
    return `Our fixed monthly payment option (Aven Simple Loan) is available based on your Var APR plus fees not exceeding the High Cost Mortgage threshold set by law. Your rate will not increase while the Aven Simple Loan plan is open.`
}

export const contingentOfferFootnote = (): string => {
    return `Offer is based on initial data and estimates terms you may qualify for. Any offer is subject to home card application completion and current, verified data for the home application. Terms may vary from those presented and there is no guarantee you will qualify.`
}

export const estimatedMonthlyPayments = (): string => {
    return `To estimate your Aven monthly payment, we assumed a 30-year term and APR based on initial data and estimated terms you may qualify for. Your actual Aven monthly payment might differ based on additional credit profile information and the product options you select and might not be lower than your current monthly payment.`
}

export const ENGLISH_TRANSLATIONS = {
    components: {
        avenFinancialCardOffer: {
            limitedTime: 'LIMITED-TIME',
            estimatedMonthlyPayments: 'ESTIMATED MONTHLY PAYMENTS',
            amountTransferred: 'AMOUNT TRANSFERRED',
            homeEquity: 'HOME EQUITY',
            thirtyYearFixed: '30-YEAR FIXED',
            disclosure2: 'Estimated payments above assume a fixed APR of {apr} for 30-year plans.<sup>2</sup>',
            personalLoanRefi: {
                yourPersonalLoan: 'YOUR PERSONAL LOAN',
                buttonCta: `Lower Payment to {monthlyPayment}/mo<sup>1</sup>`,
                description: `<span class="tw-text-black tw-font-bold">Reduce your personal loan payment</span> to as low as {monthlyPayment}<sup>1</sup> when you refinance with Aven.`,
            },
            creditCardBalanceTransfer: {
                allAccounts: 'All Accounts',
                description: `<span class="tw-text-black tw-font-bold">Lower your monthly payment </span><sup>1</sup> when you transfer all your credit card balances to Aven.`,
            },
            helocRefi: {
                buttonCta: `Reduce My Monthly Payment`,
                description: `<span class="tw-text-black tw-font-bold">Lower your monthly HELOC payment – guaranteed</span> when you refinance with Aven. We’ll beat or match any offer.<sup>1</sup>`,
            },
            genericAvenCash: {
                buttonCta: `View My Offer`,
                description: `<span class="tw-text-black tw-font-bold">Get the lowest HELOC monthly payments – guaranteed</span> with Aven Home Cash. We’ll beat or match any offer.<sup>1</sup>`,
            },
            genericMoap: {
                title: 'Aven Home Equity Card',
                description: `<span class="tw-text-black">100% online and as fast as 15 minutes.</span>`,
                creditLimitUpTo: 'Credit limit up to<sup>2</sup>',
                aprAsLowAs: 'Var APR as low as<sup>2</sup>',
                unlimitedCashBack: 'Unlimited cash back<sup>1</sup>',
                noCostNoObligation: 'NO COST, NO OBLIGATION<sup>1</sup>',
            },
            dryrunMoap: {
                description: `You’re prequalified. 100% online.`,
                creditLimit: 'Credit Limit<sup>1</sup>',
                varApr: 'Var APR<sup>1</sup>',
                cashBack: 'Cash Back<sup>1</sup>{annualLimit}',
                noCostNoObligation: 'NO COST, NO OBLIGATION<sup>1</sup>',
            },
            extraInformation: {
                whyIsThisBetterThenPL: {
                    title: 'Why is this better then a personal loan?',
                    body: 'We give lower rates than a personal loan with low fixed monthly payments<sup>1</sup>.',
                },
                whatIsAven: {
                    title: 'What is Aven? How does this work?',
                    body: 'Aven uses your home equity, like a home equity line of credit (HELOC), to get you low fixed rates.',
                },
                howLongWillThisTake: {
                    title: 'How long will this take?',
                    body: 'Our process is 100% online and as fast as 15 minutes.',
                },
                homeEquity: {
                    title: 'I’m worried about using my home equity.',
                    body: 'We offer guaranteed home foreclosure protection<sup>2</sup> for up to $10K in balances if you lose your job for up to a year.',
                },
            },
        },
    },
}
