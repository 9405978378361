import { AdcOverrideCustomerResponse, DisputeReasonDesc, IDisputeQuestionnaire } from '@/data/constants'
import { appSessionStorage, localStorageKey } from '@/utils/storage'
import { httpClient } from '@/services/http-client'
import { APIResponse } from '@/models/APIResponse'
import { AvenDeviceRuntimeType } from '@/utils/deviceUtils'
import { AxiosResponse } from 'axios'
import { PifRewardType } from '@/store/overviewStore.types'
import { PifScreen } from '@/utils/pifManager'
import { AmortizationSource, AvenCardProductType, AvenMyNextAction, HomePreQualOfferUpgradeResponse, ICreditCardCustomerJwtToken, ProductInfoDetails } from 'aven_types'

export interface SmsTwoFactorCodePayload {
    phoneNumber: string
    deviceGuid: string
    codeDeliveryMethod: string
}

export interface EmailTwoFactorCodePayload {
    email: string
    deviceGuid: string
    codeDeliveryMethod: string
}

export interface VerifyOtpCodePayload {
    verificationCode: string
    sid: string
}

// Keep in sync with SetPasswordErrors in aven_backend/src/controller/avenPasscodeController.ts
export enum SetPasswordErrors {
    PASSCODE_RESET_TOKEN_EXPIRED = 'PASSCODE_RESET_TOKEN_EXPIRED',
    INCORRECT_SSN_OR_DOB_ERROR = 'INCORRECT_SSN_OR_DOB_ERROR',
    INVALID_AVEN_MY_PASSWORD = 'INVALID_AVEN_MY_PASSWORD',
}

// Keep in sync with PasswordLoginErrors in aven_backend/src/controller/avenAuthController.ts
export enum PasswordLoginErrors {
    SYSTEM_ACCESS_DISABLED_ERROR = 'SYSTEM_ACCESS_DISABLED_ERROR',
    ACCOUNT_LOCKED_ERROR = 'ACCOUNT_LOCKED_ERROR',
    INCORRECT_PASSWORD_ERROR = 'INCORRECT_PASSWORD_ERROR',
}

// TODO: this will become request sessionId that doesn't require any customer identifying information
// once customer logins successfully, then session is linked to customer.
// then the session can associated with the correct device since browser fingerprint is not guaranteed to be unique
export const blockStatus = async (payload) => {
    return await httpClient.post('/aven_app/blockStatus', payload)
}

interface SessionIdResponse {
    sessionId: string
    sessionAccessJWT: string
    sessionExpiry: number
}

export interface SessionIdPayload {
    deviceGuid: string | undefined
    deviceName: string | undefined
    deviceModel: string | undefined
    browser: string | undefined
    resolution: string | undefined
    os: string | undefined
    appVersion: string | undefined
    runtimeType: AvenDeviceRuntimeType | undefined
    platform: string | undefined
}

export const sessionId = async (payload: SessionIdPayload): Promise<AxiosResponse<APIResponse<SessionIdResponse>>> => {
    return await httpClient.post('/aven_app/v2/sessionId', payload)
}

export const trackClick = async (link: string): Promise<AxiosResponse<APIResponse<undefined>>> => {
    return await httpClient.post('/aven_app/clk', { link })
}

export enum TwilioErrorCodes {
    /**
     * E.g. a landline. https://www.twilio.com/docs/api/errors/60205
     */
    PHONE_CANNOT_RECEIVE_SMS = 60205,
}

export const getAvenMyNextActionFromPhoneNumberAndDeepLink = async ({
    phoneNumber,
    deepLink,
}: {
    phoneNumber: string
    deepLink?: string
}): Promise<AxiosResponse<APIResponse<{ nextAction: AvenMyNextAction }>>> => {
    return await httpClient.post('/aven_app/getAvenMyNextActionFromPhoneNumber', { phoneNumber, deepLink })
}

export const createOriginationSession = async (sessionId: string): Promise<AxiosResponse<APIResponse>> => {
    return await httpClient.post('/ana/session', { sessionId, avenProperty: 'ORIGINATION' })
}

export const createOriginationLead = async (phoneNumber: string, sessionId: string): Promise<AxiosResponse<APIResponse<{ returnLink: string }>>> => {
    return await httpClient.post('/lead', { phoneNumber, sessionId })
}

export const requestOtpCode = async (requestPayload: SmsTwoFactorCodePayload | EmailTwoFactorCodePayload) => {
    const response = await httpClient.post('/aven_app/v2/request2FACode', requestPayload)
    if (response.data.success) {
        appSessionStorage.setItem(localStorageKey.creditCardCustomerId, response.data.payload.creditCardCustomerId)
        appSessionStorage.setItem(localStorageKey.displayPhoneNumber, response.data.payload.prettyPhoneNumber)
        appSessionStorage.setItem(localStorageKey.displayEmail, response.data.payload.displayEmail)
        appSessionStorage.setItem(localStorageKey.customerFullName, response.data.payload.customerFullName)
    }
    return response
}

export const verifyOtpCode = async (verifyCodePayload: VerifyOtpCodePayload) => {
    return await httpClient.post('/aven_app/v2/verify2FACode', verifyCodePayload)
}

export const setPassword = async (token: string, password: string, last4Ssn: string, dateOfBirth: string) => {
    return await httpClient.post(`/aven_app/setPasscode/${token}`, {
        passcode: password,
        last4Ssn,
        dateOfBirth,
    })
}

export const requestSetPasswordToken = async () => {
    return await httpClient.get('/aven_app/requestPasscodeResetToken')
}

export const passwordLogin = async (password: string, productType: AvenCardProductType): Promise<AxiosResponse<APIResponse<{ jwt: ICreditCardCustomerJwtToken }>>> => {
    return await httpClient.post('/aven_app/v2/passcodeLogin', {
        creditCardCustomerId: parseInt(appSessionStorage.getItem(localStorageKey.creditCardCustomerId)),
        productType,
        passcode: password,
    })
}

// Keep in sync with ProductInfoResponse in aven_backend/src/manager/unifiedDebtProductManager.ts
export interface ProductInfoResponse {
    productInfoDetails: ProductInfoDetails[]
    customerTimeZone: string
}

export const authorizePasswordAndGetProductInfo = async (password: string): Promise<AxiosResponse<APIResponse<ProductInfoResponse>>> => {
    return await httpClient.post('/aven_app/loginAndGetProductInfo', {
        creditCardCustomerId: parseInt(appSessionStorage.getItem(localStorageKey.creditCardCustomerId)),
        passcode: password,
    })
}

export const switchProducts = async (productType: AvenCardProductType, last4RedactedAccessDeviceNumber: string): Promise<AxiosResponse<APIResponse<{ jwt: ICreditCardCustomerJwtToken }>>> => {
    return await httpClient.post('/aven_app/switchProducts', {
        productType,
        last4RedactedAccessDeviceNumber,
    })
}

export const postBeginFastFunding = async () => httpClient.post('/aven_app/beginFastFunding', {})

export const getAccountBalanceTransferAndCashOutData = async () => httpClient.get('/aven_app/getAccountBalanceTransferAndCashOutData')

// Keep in sync with DebtProductAndMetaDataSet in aven_backend/src/controller/aven/creditCardCustomerAccountOverviewController.ts
export enum DebtProductAndMetaDataSet {
    /** Both dynamic & static data */
    all = 'all',
    /** Only dynamic data. I.e. data that can change based on user action w/ in an Aven My session. */
    dynamic = 'dynamic',
    /** Only static data. I.e. data that cannot change based on user action w/ in an Aven My session. */
    static = 'static',
}

export const getDebtProductAndMetaData = async (dataSet: DebtProductAndMetaDataSet = DebtProductAndMetaDataSet.all) => {
    return await httpClient.get('/aven_app/getDebtProductAndMetaData', { params: { dataSet } })
}

// Keep in sync with GetEstimatedAvenSavingsResponse in aven_backend/src/controller/estimatedAvenSavingsController.ts
export interface GetEstimatedAvenSavingsResponse {
    currentAccountBalanceDollars: number
    estimatedYearlyInterestAvgCardDollars: number
    avgCardApr: number
    estimatedYearlyInterestAvenCardDollars: number
    avenCardApr: number
}

export const getEstimatedAvenSavings = async (): Promise<AxiosResponse<APIResponse<GetEstimatedAvenSavingsResponse | undefined>>> => {
    return httpClient.get('/aven_app/getEstimatedAvenSavings')
}

// Statement
export const statementList = async () => {
    return await httpClient.get('/aven_app/statementList')
}

// get statement
export const getStatementPdf = async (statementId: number) => {
    return await httpClient.get(`/aven_app/statement/${statementId}`, { responseType: 'blob' })
}

// Tax documents
export const taxDocumentList = async () => {
    return await httpClient.get('/aven_app/taxDocumentList')
}

export const delinquencyNoticeList = async () => {
    return await httpClient.get('/aven_app/delinquencyNoticeList')
}

export const customerNoticeList = async () => {
    return await httpClient.get('/aven_app/customerNoticeList')
}

export const getPayItForwardLinks = async (
    screenNames: PifScreen[]
): Promise<
    AxiosResponse<{
        success: boolean
        error?: string
        payload?: { shareLinks: { screenName: PifScreen; link: string }[]; pifCode: string; maxAmount: number | null; rewardType: PifRewardType } | null
    }>
> => {
    return await httpClient.post('/aven_app/payItForwardLinks', {
        screenNames: screenNames,
    })
}

export const submitPayItForwardTip = async (tipAmount) => {
    return await httpClient.post('/aven_app/payItForward/saveTipAmount', {
        sourceTip: tipAmount,
    })
}

export const replaceCard = async (reason: string) => {
    return await httpClient.post('/aven_app/replaceCard', {
        reason: reason,
    })
}

export const submitTransactionDispute = async (transactionId: string, vrolDisputeReason: DisputeReasonDesc, disputeQuestionnaire: IDisputeQuestionnaire, documentUploadIds?: number[]) => {
    return await httpClient.post('/aven_app/disputes/raiseTransactionDispute', {
        transactionId,
        vrolDisputeReason,
        disputeQuestionnaire,
        documentUploadIds,
    })
}

export const cancelTransactionDispute = async (transactionId: string) => {
    return await httpClient.post('/aven_app/disputes/cancelTransactionDispute', {
        transactionId,
    })
}

export const getTransactionDetails = async (transactionId: string) => {
    return await httpClient.post('/aven_app/disputes/getTransactionDetails', {
        transactionId,
    })
}

export const getSimpleInterestConversionLoanTerms = async () => {
    return await httpClient.get('/aven_app/getSimpleInterestConversionLoanTerms')
}

export interface ISingleSimpleLoanConversionAcceptPayload {
    amortizationLoanId: number
    simpleInterestLoanId: number
    simpleInterestLoanConversionOfferId: number
}

export const acceptSimpleInterestLoanConversionOffer = async (acceptedConversionOfferIds?: ISingleSimpleLoanConversionAcceptPayload[]) => {
    return await httpClient.post('/aven_app/acceptSimpleInterestLoanConversionOffer', { acceptedConversionOfferIds })
}

export const canAccountHaveFixedInstallmentLoanRequest = async (source: AmortizationSource) => {
    return await httpClient.post('/canAccountHaveFixedInstallmentLoan', {
        source,
    })
}

export const createCallBookingLink = async (phoneNumber: string) => {
    return await httpClient.post('/support/createCallBookingLink', {
        phoneNumber,
    })
}

export enum FrontendAdcOverrideError {
    EXPIRED = 'EXPIRED',
    ALREADY_ANSWERED = 'ALREADY_ANSWERED',
    INCORRECT_SSN = 'INCORRECT_SSN',
    INCORRECT_SSN_CARD_FROZEN = 'INCORRECT_SSN_CARD_FROZEN',
}

export const getMostRecentAdcOverride = async () => {
    return await httpClient.get('/aven_app/getMostRecentAdcOverride')
}

export const submitAdcOverrideResponse = async (adcOverrideId: number, customerResponse: AdcOverrideCustomerResponse, last4Ssn: string | undefined) => {
    return await httpClient.post('/aven_app/submitAdcOverrideResponse', {
        adcOverrideId,
        customerResponse,
        last4Ssn,
    })
}

export const isEligibleToCreateAtn = async () => {
    return await httpClient.post('/advanceTransactionNotification/isEligibleToCreate')
}

export const getMostRecentOngoingAtn = async () => {
    return await httpClient.post('/advanceTransactionNotification/getMostRecentOngoing')
}

export const getFutureStartDatesOfAtn = async () => {
    return await httpClient.post('/advanceTransactionNotification/getFutureStartDates')
}

export const createAtn = async (advanceTransactionNotificationFrom: Date, advanceTransactionNotificationTo: Date, advanceTransactionNotificationAmountCents: number) => {
    return await httpClient.post('/advanceTransactionNotification/create', {
        advanceTransactionNotificationFrom,
        advanceTransactionNotificationTo,
        advanceTransactionNotificationAmountCents,
    })
}

export const cancelAtn = async (advanceTransactionNotificationId: number) => {
    return await httpClient.post('/advanceTransactionNotification/cancel', {
        advanceTransactionNotificationId,
    })
}

interface GetCreditCardMarketDataResponse {
    dateUpdatedString: string
    sourceArticleName: string
    sourceWebsiteLinkText: string
    sourceWebsiteUrl: string
    avgGoodCreditApr: number
    avgNewOffersApr: number
}

export const getCreditCardMarketData = async (): Promise<AxiosResponse<APIResponse<{ data: GetCreditCardMarketDataResponse }>>> => {
    return await httpClient.get('/aven_app/getCreditCardMarketData')
}

export const beginUccCardholderToHomeUpgradeDataFetchAndPreQualificationOffer = async (newUwPolicyName?: string): Promise<AxiosResponse<APIResponse<{ jobId: string }>>> => {
    return await httpClient.post('/aven_app/beginUccCardholderToHomeUpgradeDataFetchAndPreQualificationOffer', { newUwPolicyName })
}

export const getUccCardholderHomeUpgradePreQualOffer = async (jobId: string): Promise<AxiosResponse<APIResponse<HomePreQualOfferUpgradeResponse>>> => {
    return await httpClient.get(`/aven_app/getUccCardholderHomeUpgradePreQualOffer?jobId=${jobId}`)
}
